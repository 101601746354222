<template>
    <button
        :disabled="disabled"
        :type="type"
        :class="buttonStyle"
        v-bind="$attrs">
        <slot name="icon">
            <Icon
                v-if="icon"
                :name="icon"
                :class="iconStyle" />
        </slot>
        <slot />
    </button>
</template>

<script setup>
const props = defineProps({

    //incompatible with deep integration with headlessui. Please only use props.color. Remains for backwards compatibility
    type: {
        type: String,
        default: 'secondary'
    },
    color: {
        type: String,
        default: null
    },
    size: {
        type: String,
        default: 'md'
    },
    icon: {
        type: String,
        default: null
    },
    disabled: {
        type: Boolean,
        default: false
    },
    noPadding: {
        type: Boolean,
        default: false
    },
    noShadow: {
        type: Boolean,
        default: false
    }
})

const colorStyles = {
    primary: 'bg-primary-600 hover:bg-primary-500 text-white',
    secondary: 'bg-white hover:bg-gray-50 text-gray-900 dark:text-white dark:bg-white/10 dark:hover:bg-white/20 ring-1 ring-inset ring-gray-300',
    borderless: 'bg-white text-gray-900 dark:text-white dark:bg-white/10',
    danger: 'bg-danger-600 hover:bg-danger-500 text-white'
}

const roundedStyles = {
    xs: 'rounded',
    sm: 'rounded',
    md: 'rounded-md',
    lg: 'rounded-md',
    xl: 'rounded-md',
}

const textStyles = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-sm',
    lg: 'text-sm',
    xl: 'text-sm',
}

const paddingStyles = {
    xs: 'px-2 py-1',
    sm: 'px-2 py-1',
    md: 'px-2.5 py-1.5',
    lg: 'px-3 py-2',
    xl: 'px-3.5 py-2.5',
}

const iconSizingStyles = {
    xs: '-mr-0.5 h-5 w-5',
    sm: '-mr-0.5 h-5 w-5',
    md: '-mr-0.5 h-5 w-5',
    lg: '-mr-0.5 h-5 w-5',
    xl: '-mr-0.5 h-5 w-5',
}

const buttonStyle = computed(() => {
    const styles = ['font-semibold']

    // fallback to props.type if color is not defined
    styles.push(colorStyles[props.color ? props.color: props.type])
    styles.push(textStyles[props.size])

    if (!props.noPadding)
        styles.push(paddingStyles[props.size])

    if (!props.noShadow)
        styles.push('shadow-sm')

    if (props.round) {
        styles.push('rounded-full')
    } else {
        styles.push(roundedStyles[props.size])
    }

    if (props.disabled) {
        styles.push('opacity-50 cursor-not-allowed')
    }

    if (props.icon) {
        styles.push('flex items-center justify-center gap-2')
    }

    return styles.join(' ')
})

const iconStyle = computed(() => {
    return iconSizingStyles[props.size]
})
</script>

<style scoped>

</style>